import { discountActions } from "./discount-slice";

export const GetDiscountAvailable = (email, userCounts, conferenceCode) => {
  return async (dispatch) => {
    const getDiscountInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}conferences/discount/getDiscountAvailable`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            UserCounts: userCounts,
            Conference: {
              ConferecenCodeName: conferenceCode,
            },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener el descuento");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getDiscountInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          discountActions.setDiscount({
            discount: response.data,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          discountActions.setDiscount({
            discount: response.data,
          })
        );
      }
    } catch (error) {}
  };
};

import "primeicons/primeicons.css";
import React from "react";
const Loading = (props) => {
  return (
    <>
      <span
        style={{
          fontSize: props.fontSize ? props.fontSize : "2rem",
          color: props.color ?? "#212C40",
        }}
      >
        <i
          className="pi pi-spin pi-cog"
          style={{
            fontSize: props.fontSize ? props.fontSize : "2rem",
            color: props.color ?? "#212C40",
          }}
        ></i>
        {"  "}
        cargando...
      </span>
    </>
  );
};
export default Loading;

import React from "react";
import { useRef, useEffect } from "react";
import { Toast } from "primereact/toast";
import { useSelector } from "react-redux";

const Notification = (props) => {
  const toast = useRef(null);
  const notification = useSelector((state) => state.ui.notification);

  useEffect(() => {
    if (notification) {
      const showSuccess = () => {
        toast.current.show({
          severity: "success",
          summary: props.title,
          detail: props.message,
          life: 4500,
        });
      };
      const showInfo = () => {
        toast.current.show({
          severity: "info",
          summary: props.title,
          detail: props.message,
          life: 4500,
        });
      };

      const showWarn = () => {
        toast.current.show({
          severity: "warn",
          summary: props.title,
          detail: props.message,
          life: 4500,
        });
      };

      const showError = () => {
        toast.current.show({
          severity: "error",
          summary: props.title,
          detail: props.message,
          life: 4500,
        });
      };

      if (props.status === "pending") {
        showInfo();
      }
      if (props.status === "success") {
        showSuccess();
      }
      if (props.status === "warning") {
        showWarn();
      }
      if (props.status === "error") {
        showError();
      }
    }
  }, [notification, props.message, props.status, props.title]);

  return (
    <div className="card flex justify-content-center">
      <Toast ref={toast} />
    </div>
  );
};

export default Notification;

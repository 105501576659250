import React from "react";
import { useRef, useState } from "react";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { useNavigate, useParams } from "react-router-dom";
import { IsValidInfo, getTokenInfo } from "../utils/Guard";
import { useDispatch } from "react-redux";
import { ForgotPasswordInsideAction } from "../store/user-store/user-actions";
import YesNoModal from "../components/YesNoModal";

const UserMenu = (props) => {
  const menuLeft = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [visibleChangePassword, setVisibleChangePassword] = useState(false);

  const items = [
    {
      template: () => {
        return (
          <Button
            className="NavBarUserName"
            label={
              userInfo !== null &&
              userInfo.FullName !== undefined &&
              userInfo.FullName !== " "
                ? userInfo.FullName
                : "{User Name}"
            }
          />
        );
      },
    },
    {
      template: () => {
        return (
          <>
            {IsValidInfo() && (
              <Button
                className="NavBarUserOptions"
                label="Ver órdenes"
                onClick={() => {
                  navigate("/orden/" + params.conferenceCode);
                }}
              />
            )}
          </>
        );
      },
    },
    {
      template: () => {
        return (
          <>
            {IsValidInfo() && (
              <Button
                label="Cambio de contraseña"
                className="NavBarUserOptions"
                onClick={() => {
                  setVisibleChangePassword(true);
                }}
              />
            )}
          </>
        );
      },
    },
    {
      template: () => {
        return (
          <>
            <Button
              label="Cerrar sesión"
              severity="danger"
              size="small"
              icon="pi pi-fw pi-power-off"
              className="logoutButton"
              onClick={props.logoutHandler}
            />
          </>
        );
      },
    },
  ];
  const headerChangePassWord = (
    <>
      <span className="NavchangePassWord">
        {" "}
        ¿Desea solicitar cambio de contraseña?{" "}
      </span>
    </>
  );
  const contentPaymentMethod = (
    <>
      <span className="NavBodychangePassWord">
        {" "}
        Se enviará un correo electrónico, con las instrucciones para el cambio de
        contraseña.{" "}
      </span>
    </>
  );
  const userInfo = getTokenInfo();
  const changePasswordRequest = () => {
    dispatch(ForgotPasswordInsideAction(userInfo.Email, params.conferenceCode ));
    setVisibleChangePassword(false);
  };

  return (
    <>
      <YesNoModal
        className="p-"
        header={headerChangePassWord}
        content={contentPaymentMethod}
        visible={visibleChangePassword}
        setVisible={setVisibleChangePassword}
        yesHandler={changePasswordRequest}
        showButton={true}
        yesButtonLabel="Solicitar"
        icon="pi pi-fw pi-check"
      />
      <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
      <Avatar
        icon="pi pi-user"
        size="xlarge"
        shape="circle"
        className="NavBarUserIcon"
        onClick={(event) => menuLeft.current.toggle(event)}
      />
    </>
  );
};
export default UserMenu;

import { configureStore } from "@reduxjs/toolkit";
import UISlice from "./ui-slice";

import userSlice from "./user-store/user-slice";
import chargeSlice from "./charge-store/charge-slice";
import departmentSlice from "./department-store/department-slice";

import ordenSlice from "./orden-store/orden-slice";
import discountSlice from "./discount-store/discount-slice";
import conferenceSlice from "./conferences-store/conference-slice";
const store = configureStore({
  reducer: {
    ui: UISlice.reducer,
    user: userSlice.reducer,
    charge: chargeSlice.reducer,
    department: departmentSlice.reducer,
    orden: ordenSlice.reducer,
    discount: discountSlice.reducer,
    conference: conferenceSlice.reducer,
  },
});

export default store;

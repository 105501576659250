import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { Card } from "primereact/card";
import { userActions } from "../../store/user-store/user-slice";
import { GetCharges } from "../../store/charge-store/charge-actions";
import { GetDepartments } from "../../store/department-store/department-actions";

import { isValidEmail } from "../../utils/ValidateUserInfo";
import { arregloPaises } from "../List";
import { useEffect, useState } from "react";

import "./UserFormDetail.css";
import { getTokenInfo } from "../../utils/Guard";
import Loading from "../UI/Loading";
import { Checkbox } from "primereact/checkbox";

const UserFormDetail = (props) => {
  const dispatch = useDispatch();
  const isValid = useSelector((state) => state.user.userValidation);
  const user = useSelector((state) => state.user.user);
  const charge = useSelector((state) => state.charge);
  const department = useSelector((state) => state.department);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCharge, setSelectedCharge] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedNetworking, setSelectedNetworking] = useState(false);
  const userInfo = getTokenInfo();

  useEffect(() => {
    if (
      department.departments === undefined ||
      department.departments.length === 0
    ) {
      dispatch(GetDepartments(userInfo.Email, true));
    }
  }, [userInfo.Email, department, dispatch]);
  useEffect(() => {
    if (charge.charges === undefined || charge.charges.length === 0) {
      dispatch(GetCharges(userInfo.Email, true));
    }
  }, [userInfo.Email, dispatch, charge]);

  useEffect(() => {
    if (
      user.country !== undefined &&
      user.country !== "" &&
      selectedCountry === null
    ) {
      const pais = arregloPaises.find((pais) => pais.nombre === user.country);
      setSelectedCountry({
        code: user.country,
        name: user.country,
        flag: pais !== undefined ? pais.abreviatura : "",
      });
    }
    if (
      user.charge !== undefined &&
      user.charge.id !== undefined &&
      selectedCharge === null
    ) {
      setSelectedCharge({
        code: user.charge.id,
        name: user.charge.name,
      });
    }
    if (
      user.department !== undefined &&
      user.department.id !== undefined &&
      selectedDepartment === null
    ) {
      setSelectedDepartment({
        code: user.department.id,
        name: user.department.name,
      });
    }
  }, [user.country, selectedCountry, user, selectedCharge, selectedDepartment]);

  let charges = [];
  if (charge.charges !== undefined && charge.charges.length > 0) {
    charges = charge.charges.filter((charge) => charge.status === "Activo");
    charges = charges.map((charge) => {
      return {
        code: charge.id,
        name: charge.name,
      };
    });
  }
  let departments = [];
  if (
    department.departments !== undefined &&
    department.departments.length > 0
  ) {
    departments = department.departments.filter(
      (department) => department.status === "Activo"
    );
    departments = departments.map((department) => {
      return {
        code: department.id,
        name: department.name,
      };
    });
  }

  const onChangeHandler = (id, value) => {
    if (id === "charge" || id === "department") {
      if (id === "charge") {
        dispatch(
          userActions.modifyPropertyValue({
            id,
            value: { id: value.code, name: value.name },
          })
        );
        setSelectedCharge({
          code: value.code,
          name: value.name,
        });
      }
      if (id === "department") {
        dispatch(
          userActions.modifyPropertyValue({
            id,
            value: { id: value.code, name: value.name },
          })
        );
        setSelectedDepartment({
          code: value.code,
          name: value.name,
        });
      }
    } else {
      dispatch(userActions.modifyPropertyValue({ id, value }));
      if (value !== "") {
        if (id === "email") {
          if (isValidEmail(value)) {
            dispatch(userActions.setFieldValid({ id, value: true }));
          } else {
            dispatch(userActions.setFieldValid({ id, value: false }));
          }
        } else if (
          id === "name" ||
          id === "lastName" ||
          id === "email" ||
          id === "cellPhone" ||
          id === "country"
        ) {
          dispatch(userActions.setFieldValid({ id, value: true }));
        }
      } else if (
        id === "name" ||
        id === "lastName" ||
        id === "email" ||
        id === "cellPhone" ||
        id === "country"
      ) {
        dispatch(userActions.setFieldValid({ id, value: false }));
      }
    }
  };
  const onChangeCountriesHandler = (id, value) => {
    dispatch(userActions.modifyPropertyValue({ id, value: value.code }));
    if (value !== "") {
      dispatch(userActions.setFieldValid({ id, value: true }));
    } else {
      dispatch(userActions.setFieldValid({ id, value: false }));
    }
    setSelectedCountry({
      code: value.code,
      name: value.name,
      flag: value.flag,
    });
  };
  const getFormErrorMessage = (id) => {
    return !isValid[id].isValid ? (
      <Message severity="warn" text={isValid[id].errorMessage} />
    ) : (
      <small className="p-warn">&nbsp;</small>
    );
  };

  const paises = arregloPaises.map((pais) => ({
    code: pais.nombre,
    name: pais.nombre,
    flag: pais.abreviatura,
  }));
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src={`https://flagcdn.com/48x36/${option.flag.toLowerCase()}.png`}
            style={{ width: "18px" }}
          />

          <div style={{ marginLeft: "5%" }}>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center" style={{ width: "100px" }}>
        <img
          alt={option.name}
          src={`https://flagcdn.com/48x36/${option.flag.toLowerCase()}.png`}
          style={{ width: "18px" }}
        />
        <div style={{ marginLeft: "5%" }}>{option.name}</div>
      </div>
    );
  };

  return (
    <>
      <div className="card mb-4 ">
        <Card className="userFormDetailCard">
          <form onSubmit={props.onSubmitHandler}>
            <div className="formgrid grid">
              <div className="field col-12 md:col-12">
                <label htmlFor="name" className="userFormDetailLabel">
                  Nombres:
                </label>
                <InputText
                  id="name"
                  value={user.name}
                  onChange={(e) => onChangeHandler("name", e.target.value)}
                  placeholder="Nombres"
                  className="userFormDetailInput"
                />
                <div>{getFormErrorMessage("name")}</div>
              </div>
              <div className="field col-12 md:col-12">
                <label htmlFor="lastName" className="userFormDetailLabel">
                  Apellidos:
                </label>
                <InputText
                  id="lastName"
                  value={user.lastName}
                  onChange={(e) => onChangeHandler("lastName", e.target.value)}
                  placeholder="Apellidos"
                  className="userFormDetailInput"
                />
                <div>{getFormErrorMessage("lastName")}</div>
              </div>
              <div className="field col-12 md:col-12">
                <label htmlFor="Email" className="userFormDetailLabel">
                  Email:
                </label>
                <InputText
                  id="Email"
                  value={user.email}
                  onChange={(e) => onChangeHandler("email", e.target.value)}
                  placeholder="example@email.com"
                  className="userFormDetailInput"
                />
                <div>{getFormErrorMessage("email")}</div>
              </div>
              <div className="field col-12 md:col-12">
                <p className="userFormDetailMessage">
                  Es esencial que tengamos la dirección de correo electrónico
                  directa del participante, recibirá información importante
                  relacionada con su acceso al evento.
                </p>
              </div>

              <div className="field col-12 md:col-12">
                <label htmlFor="cellPhone" className="userFormDetailLabel">
                  Teléfono Celular:
                </label>
                <InputText
                  id="cellPhone"
                  value={user.cellPhone}
                  onChange={(e) => onChangeHandler("cellPhone", e.target.value)}
                  placeholder=""
                  className="userFormDetailInput"
                />
                <div>{getFormErrorMessage("cellPhone")}</div>
              </div>
            </div>
            <div className="field col-12 md:col-12">
              <label htmlFor="country" className="userFormDetailLabel">
                País:
              </label>
              <br />
              <Dropdown
                value={selectedCountry}
                onChange={(e) => {
                  onChangeCountriesHandler("country", e.value);
                }}
                options={paises}
                optionLabel="name"
                placeholder="Seleccionar pais"
                valueTemplate={selectedCountryTemplate}
                filter
                itemTemplate={countryOptionTemplate}
                className="userFormDetailDropdown"
              />
              <div>{getFormErrorMessage("country")}</div>
            </div>
            <h2 className="mt-3 fw-normal userFormDetailTitle">
              Información Laboral
            </h2>
            <div className="formgrid grid">
              <div className="field col-12 md:col-12">
                <label htmlFor="company" className="userFormDetailLabel">
                  Empresa:
                </label>
                <InputText
                  id="company"
                  value={user.company}
                  onChange={(e) => onChangeHandler("company", e.target.value)}
                  placeholder="Empresa"
                  className="userFormDetailInput"
                />
              </div>
              {charges.length <= 0 && (
                <div className="field col-12 md:col-12">
                  <Loading />
                </div>
              )}
              {charges.length > 0 && (
                <div className="field col-12 md:col-12">
                  <label htmlFor="charge" className="userFormDetailLabel">
                    Cargo:
                  </label>
                  <Dropdown
                    id="cargo"
                    value={selectedCharge}
                    onChange={(e) => onChangeHandler("charge", e.value)}
                    options={charges}
                    optionLabel="name"
                    placeholder="Seleccionar cargo"
                    filter
                    className="userFormDetailDropdown"
                  />
                </div>
              )}

              {departments.length <= 0 && (
                <div className="field col-12 md:col-12">
                  <Loading />
                </div>
              )}
              {departments.length > 0 && (
                <div className="field col-12 md:col-12">
                  <label htmlFor="department" className="userFormDetailLabel">
                    Departamento:
                  </label>
                  <Dropdown
                    id="department"
                    value={selectedDepartment}
                    onChange={(e) => onChangeHandler("department", e.value)}
                    options={departments}
                    optionLabel="name"
                    placeholder="Seleccionar departamento"
                    filter
                    className="userFormDetailDropdown"
                  />
                </div>
              )}
              <h2 className="mt-3 fw-normal userFormDetailTitle">Networking</h2>
              <div className="field col-12 md:col-12">
                <p className="userFormDetailMessage">
                  Las reuniones y la creación de conexiones son una parte
                  importante de este evento. Proporcionamos a nuestros
                  asistentes la opción de conectar entre sí.
                </p>
                <br />
                <Checkbox
                  className="userFormDetailCheckbox"
                  onChange={(e) => {
                    dispatch(
                      userActions.modifyPropertyValue({
                        id: "networking",
                        value: e.checked,
                      })
                    );
                    setSelectedNetworking(e.checked);
                  }}
                  checked={selectedNetworking}
                ></Checkbox>
                &nbsp;&nbsp;
                <span>Acepto formar parte</span>
              </div>
              <h2 className="mt-3 fw-normal userFormDetailTitle">
                Comunicación
              </h2>
              <div className="field col-12 md:col-12">
                <p className="userFormDetailMessage">
                  Es posible que le enviemos correos electrónicos de vez en
                  cuando con actualizaciones y otra información sobre nuestros
                  eventos, productos y servicios que podrían ser de su interés.
                  Puede darse de baja en cualquier momento.
                </p>
              </div>
              <div className="field col-12 md:col-12">{props.buttons}</div>
            </div>
          </form>
        </Card>
      </div>
    </>
  );
};
export default UserFormDetail;

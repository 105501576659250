import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button } from "primereact/button";
import { Card } from "primereact/card";

import Loading from "../../../components/UI/Loading";

import {
  GetOrdensById,
  UpdatePayment,
} from "../../../store/orden-store/orden-actions";
import { getTokenInfo } from "../../../utils/Guard";

import "./OrdenDeclined.css";

let update = true;

const OrdenDeclined = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const params = useParams();
  const navigate = useNavigate();

  const userInfo = getTokenInfo();
  const orden = useSelector((state) => state.orden.orden);
  const transacion = useSelector((state) => state.orden.transaction);
  const [amountDollar, setAmountDollar] = useState(0);
  const [amountRD, setAmountRD] = useState(0);
  const [count, setCount] = useState(0);

  const paymentInfo = {
    OrderNumber: searchParams.get("OrderNumber"),
    Amount: amountRD,
    AuthorizationCode: searchParams.get("AuthorizationCode"),
    DateTime: searchParams.get("DateTime"),
    ResponseCode: searchParams.get("ResponseCode"),
    IsoCode: searchParams.get("IsoCode"),
    ResponseMessage:
      searchParams.get("ResponseMessage") === "ERROR"
        ? "Declinada"
        : searchParams.get("ResponseMessage"),
    ErrorDescription: searchParams.get("ErrorDescription"),
    RRN: searchParams.get("RRN"),
    CustomOrderId: searchParams.get("OrderNumber"),
    CardNumber: searchParams.get("CardNumber").split("****")[1],
    AzulOrderId: searchParams.get("AzulOrderId"),
  };

  useEffect(() => {
    if (orden && (orden.id === undefined || orden.id === 0)) {
      dispatch(GetOrdensById(userInfo.OrdenId, userInfo.Email));
    }
  }, [dispatch, orden, userInfo.Email, userInfo.OrdenId]);

  useEffect(() => {
    if (orden && orden.id !== undefined && orden.id > 0) {
      let counting = 0;
      orden.users.forEach((user) => {
        if (user.id !== 0) {
          counting++;
        }
      });
      setCount(counting);
    }
  }, [orden, count]);

  useEffect(() => {
    if (orden && orden.id !== undefined && orden.id > 0) {
      if (
        orden.discount &&
        orden.discount.id !== undefined &&
        orden.discount.id !== 0 &&
        count > 0
      ) {
        let discount = 0;
        if (orden.discount.percentage) {
          discount =
            orden.conference.price *
            count *
            (orden.discount.discountPercentage / 100);
        }
        if (orden.discount.nominal) {
          discount = orden.discount.discountNominal * count;
        }
        const dollar = orden.conference.price * count;
        const rd = (dollar - discount) * orden.dollarRate;

        setAmountDollar(dollar - discount);
        setAmountRD(rd);
      } else {
        const dollar = orden.conference.price * count;
        const rd = dollar * orden.dollarRate;
        setAmountDollar(dollar);
        setAmountRD(rd);
      }
    }
  }, [orden, count]);

  useEffect(() => {
    if (orden && orden.id !== undefined && orden.id > 0 && update) {
      update = false;
      dispatch(UpdatePayment(orden.id, userInfo.Email, paymentInfo));
    }
  }, [orden]);

  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <>
      <div className="card flex justify-content-center ">
        <div className="formgrid grid">
          <div className="field col-12 md:col-12">
            <Card className="ordenDeclinedBox">
              {orden &&
                (orden.id === undefined ||
                  orden.id === 0 ||
                  transacion === undefined) && <Loading />}
              {orden && orden.id !== undefined && orden.id > 0 && (
                <div className="formgrid grid">
                  <div className="field col-12 md:col-12 ">
                    <Button
                      id="buttonCheck"
                      icon="pi pi-times"
                      disabled={true}
                      rounded
                      aria-label="Filter"
                      className="ordenDeclinedButtonCheck"
                    />
                    <br />
                    <br />
                    <br />
                  </div>
                  <div className="field col-12 md:col-12 ordenDeclinedTitle ">
                    Pago Declinado{" "}
                  </div>
                  <div className="field col-12 md:col-12 ordenDeclinedSubtitle ">
                    <strong style={{ fontWeight: "800", color: "#000000" }}>
                      Número de órden:
                    </strong>{" "}
                    {orden.id}
                  </div>
                  <div className="field col-12 md:col-12 ordenDeclinedSubtitle ">
                    <span>
                      <strong style={{ fontWeight: "800", color: "#000000" }}>
                        Monto  en dólares
                      </strong>
                      <br /> US{USDollar.format(amountDollar)}
                    </span>
                  </div>
                  <div className="field col-12 md:col-12 ordenDeclinedSubtitle ">
                    <span>
                      <br />
                      <strong style={{ fontWeight: "800", color: "#000000" }}>
                        Razón
                      </strong>
                      <p className="ordenDeclinedMessage">
                        {transacion.longDescription}
                      </p>
                    </span>
                  </div>

                  <div className="field col-12 md:col-12 ordenDeclinedSubtitle ">
                    <Button
                      className="ordenDeclinedButtonPay"
                      label="Ir a Pagar"
                      onClick={() => {
                        navigate(`/orden/paymentInfo/${params.conferenceCode}`);
                      }}
                    />
                  </div>
                </div>
              )}
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};
export default OrdenDeclined;

import React from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button } from "primereact/button";

import { Card } from "primereact/card";
import "primereact/resources/themes/saga-blue/theme.css";
import { ValidateToken } from "../../../../store/user-store/user-actions";
import "./EmailConfirmation.css";
import { getImage } from "../../../../utils/Guard";

const EmailConfirmation = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.userId && params.token) {
      dispatch(ValidateToken(params.token, params.userId));
    }
  }, [dispatch, params.token, params.userId]);

  const header = (
    <img
      className="emailConfirmationImage"
      alt="Card"
      src={getImage(params.conferenceCode)}
    />
  );

  return (
    <>
      <div className="card flex justify-content-center emailConfirmationBody">
        <div className="formgrid grid ">
          <div className="field col-12 md:col-12">
            <Card className="emailConfirmationCard">
              <div className="formgrid grid ">
                <div className="field col-12 md:col-12">{header}</div>
                <div className="field col-12 md:col-3"></div>
                <div className="field col-12 md:col-12 emailConfirmationTitle">
                  <span className="emailConfirmationTitleText">
                    Tu cuenta se ha validado con éxito.
                  </span>
                </div>
                <div className="field col-12 md:col-12"></div>
                <div className="field col-12 md:col-12"></div>

                <div className="field col-12 md:col-12">
                  <Button
                    label="Iniciar sesión"
                    className="emailConfirmationButton"
                    onClick={() => navigate(`/login/${params.conferenceCode}`)}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};
export default EmailConfirmation;

import React from "react";
import { Menubar } from "primereact/menubar";
import { Button } from "primereact/button";
import UserMenu from "./UserMenu";

import "./NavBar.css";
import { getImage } from "../utils/Guard";
import { useParams } from "react-router-dom";

const NavBar = (props) => {
  const params = useParams();
  const items = [];

  items.push({
    template: () => {
      return (
        <Button
          label="OPORTUNIDADES DE PATROCINIO"
          className="NavBarItem"
          onClick={() => {
            window.open(
              "https://coplaft.com/wp-content/uploads/2024/07/COPLAFT-PLANES-DE-PATROCINIO.pdf",
              "_blank" // <- This is what makes it open in a new window.
            );
          }}
        />
      );
    },
  });
  items.push({
    template: () => {
      return (
        <Button
          label="AGENDA"
          className="NavBarItem"
          onClick={() => {
            window.open(
              "https://coplaft.com/#agenda",
              "_blank" // <- This is what makes it open in a new window.
            );
          }}
        />
      );
    },
  });
  // items.push({
  //   template: () => {
  //     return (
  //       <Button
  //         label="EXPERIENCIA"
  //         className="NavBarItem"
  //         onClick={() => {
  //           // navigate("/conferences");
  //         }}
  //       />
  //     );
  //   },
  // });

  const start = (
    <>
      <img
        className="NavBarImage"
        alt="logo"
        src={getImage(params.conferenceCode)}
        style={{ cursor: "pointer" }}
        onClick={() => {
          window.location.href = "/home/" + params.conferenceCode;
        }}
      ></img>
    </>
  );
  const end = (
    <div className="NavBarButtons">
      <UserMenu
        userName={props.userName}
        ordens={props.ordens}
        changePassword={props.changePassword}
        logoutHandler={props.logoutHandler}
      />
    </div>
  );

  return (
    <>
      <Menubar model={items} start={start} end={end} className="NavBar" />
    </>
  );
};
export default NavBar;

import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Card } from "primereact/card";
import logoABA from "../assets/img/LogoABA.png";
import { Button } from "primereact/button";
import { getTokenInfo } from "../utils/Guard";
import { useEffect } from "react";

const ErrorPage = () => {
  const navigate = useNavigate();
  const userinfo = getTokenInfo();
  const params = useParams();
  const location = useLocation();

  useEffect(() => {
    if (
      params.conferenceId === "undefined" ||
      params.conferenceId === undefined
    ) {
      params.conferenceId = 2;
    }
    if (location.pathname.includes("/conferences/home")) {
      navigate(`/conferences/login/${params.conferenceId}`);
    }
  }, [location.pathname, navigate, params]);

  const header = (
    <div className="bg-blue-900 pl-7 mb-0">
      <img
        alt="Card"
        className="bg-blue-900"
        src={logoABA}
        style={{ width: "40%", marginLeft: "20%", marginRight: "0" }}
      />
    </div>
  );
  return (
    <>
      <div
        className="card flex justify-content-center"
        style={{ width: "102.5%", marginLeft: "auto", marginRight: "auto" }}
      >
        <Card
          title="Página no encontrada"
          subTitle="La pagina a la cual intenta accesar, no fue encontrada."
          header={header}
          style={{ width: "100%" }}
        >
          <Button
            className="p-button-danger"
            onClick={() => {
              if (userinfo.conferenceId && userinfo.conferenceId > 0) {
                navigate(`/conferences/home/${userinfo.conferenceId}`);
              } else {
                navigate("/home");
              }
            }}
          >
            Atrás
          </Button>
        </Card>
      </div>
    </>
  );
};
export default ErrorPage;

import React from "react";
import { useParams } from "react-router-dom";
import NavBar from "./NavBar";


const NavBarMenu = () => {
  const params = useParams();

  const logoutHandler = () => {
    localStorage.setItem("token", null);
    window.location.href = "/login/" + params.conferenceCode;
  };
  return (
    <>
      <NavBar
        logoutHandler={logoutHandler}
        home={`/home/${params.conferenceCode}`}
      />
    </>
  );
};
export default NavBarMenu;

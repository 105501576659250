import React from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import "./YesNoModal.css";

const YesNoModal = (props) => {
  const footerContent = (
    <div>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        rounded
        outlined
        severity="danger"
        onClick={() => props.setVisible(false)}
        className="p-button-text"
      />
      {props.showButton && (
        <Button
          label={props.yesButtonLabel ? props.yesButtonLabel : "Si"}
          rounded
          outlined
          severity="success"
          icon={props.icon ? props.icon : "pi pi-check"}
          onClick={() => {
            props.yesHandler(true);
          }}
        />
      )}
    </div>
  );

  return (
    <div className="card flex justify-content-center">
      <Dialog
        header={props.header}
        visible={props.visible}
        // style={{ width: "100%" }}
        onHide={() => props.setVisible(false)}
        footer={footerContent}
      >
        {props.content}
      </Dialog>
    </div>
  );
};
export default YesNoModal;

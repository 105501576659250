import { isExpired, decodeToken } from "react-jwt";

export const validateToken = (conferenceCode, pathname) => {
  const token = localStorage.getItem("token");
  if (token !== null && token !== "" && isExpired(token)) {
    localStorage.removeItem("token");
    window.location.href = `/login/${conferenceCode}`;
    return false;
  }
  if (
    token === null &&
    !pathname.includes("/login") &&
    !pathname.includes("/register") &&
    !pathname.includes("/emailconfirmation") &&
    !pathname.includes("/forgotpassword") &&
    !pathname.includes("/changePassword")
  ) {
    window.location.href = `/login/${conferenceCode}`;
    return false;
  }
  if (token === null) {
    return false;
  }
  return true;
};
export const validateImage = (conferenceCode) => {
  const image = localStorage.getItem(`image${conferenceCode}`);
  if (image !== null && image !== "") {
    return true;
  }
  return false;
};
export const getTokenInfo = () => {
  return decodeToken(localStorage.getItem("token"));
};
export const getImage = (conferenceCode) => {
  return localStorage.getItem(`image${conferenceCode}`);
};
export const IsValidInfo = () => {
  const accountInfo = decodeToken(localStorage.getItem("token"));
  if (accountInfo === null) {
    return false;
  }
  if (accountInfo.Name === "" || accountInfo.Name === null) {
    return false;
  }
  if (accountInfo.LastName === "" || accountInfo.LastName === null) {
    return false;
  }
  if (accountInfo.Phone === "" || accountInfo.Phone === null) {
    return false;
  }
  if (accountInfo.Country === "" || accountInfo.Country === null) {
    return false;
  }
  if (accountInfo.Business === "" || accountInfo.Business === null) {
    return false;
  }
  if (accountInfo.Charge === "" || accountInfo.Charge === null) {
    return false;
  }
  if (accountInfo.Department === "" || accountInfo.Department === null) {
    return false;
  }
  return true;
};

import { HmacSHA512 } from "crypto-js";

export const GetHashCongresos = (
  OrderNumber,
  Amount,
  CancelUrl,
  ApprovedUrl, 
  DeclinedUrl
) => {
  const allFields =
    process.env.REACT_APP_MERCHANTID_CONGRESOS +
    process.env.REACT_APP_MERCHANT_NAME +
    process.env.REACT_APP_MERCHANT_TYPE +
    "$" +
    OrderNumber +
    Amount.replace(".", "") +
    process.env.REACT_APP_MERCHANT_ITBIS +
    ApprovedUrl +
    DeclinedUrl +
    CancelUrl +
    process.env.REACT_APP_MERCHANT_USECUSTOMFIELD1 +
    process.env.REACT_APP_MERCHANT_USECUSTOMFIELD1_LABEL +
    process.env.REACT_APP_MERCHANT_USECUSTOMFIELD1_VALUE +
    process.env.REACT_APP_MERCHANT_USECUSTOMFIELD2 +
    process.env.REACT_APP_MERCHANT_USECUSTOMFIELD2_LABEL +
    process.env.REACT_APP_MERCHANT_USECUSTOMFIELD2_VALUE +
    process.env.REACT_APP_CONGRESOS_AUTHKEY;

  const hash = HmacSHA512(allFields, process.env.REACT_APP_CONGRESOS_AUTHKEY);

  return hash;
};

export const Cargos = [
  {
    code: "Analista",
    name: "Analista",
  },
  {
    code: "Asesor",
    name: "Asesor",
  },
  {
    code: "Asistente",
    name: "Asistente",
  },
  {
    code: "Finanzas",
    name: "Finanzas",
  },
  {
    code: "Auditor",
    name: "Auditor",
  },
  {
    code: "Auxiliar",
    name: "Auxiliar",
  },
  {
    code: "Cajero",
    name: "Cajero",
  },
  {
    code: "Coordinador",
    name: "Coordinador",
  },
  {
    code: "Desarrollador",
    name: "Desarrollador",
  },
  {
    code: "Director",
    name: "Director",
  },
  {
    code: "Ejecutivo",
    name: "Ejecutivo",
  },
  {
    code: "Encargado",
    name: "Encargado",
  },
  {
    code: "Gerente",
    name: "Gerente",
  },
  {
    code: "Gestor",
    name: "Gestor",
  },
  {
    code: "Independiente",
    name: "Independiente",
  },
  {
    code: "Oficial",
    name: "Oficial",
  },
  {
    code: "Presidente",
    name: "Presidente",
  },
  {
    code: "Representante",
    name: "Representante",
  },
  {
    code: "Sub-Gerente",
    name: "Sub-Gerente",
  },
  {
    code: "Supervisor",
    name: "Supervisor",
  },
  {
    code: "Técnico",
    name: "Técnico",
  },
  {
    code: "Trader",
    name: "Trader",
  },
  {
    code: "Vice-Presidente",
    name: "Vice-Presidente",
  },
  {
    code: "Subcontralor",
    name: "Subcontralor",
  },
  {
    code: "Otros",
    name: "Otros",
  },
];

export const Departamentos = [
  {
    code: "Logística",
    name: "Logística",
  },
  {
    code: "Mercadeo",
    name: "Mercadeo",
  },
  {
    code: "Economía",
    name: "Economía",
  },
  {
    code: "Finanzas",
    name: "Finanzas",
  },
  {
    code: "Compras",
    name: "Compras",
  },
  {
    code: "Legal",
    name: "Legal",
  },
  {
    code: "Operaciones",
    name: "Operaciones",
  },
  {
    code: "Tesorería",
    name: "Tesorería",
  },
  {
    code: "Banca",
    name: "Banca",
  },
  {
    code: "Banca Empresa",
    name: "Banca Empresa",
  },
  {
    code: "Banca Personal",
    name: "Banca Personal",
  },
  {
    code: "Comunicaciones",
    name: "Comunicaciones",
  },
  {
    code: "TI",
    name: "TI",
  },
  {
    code: "Recursos Humanos",
    name: "Recursos Humanos",
  },
  {
    code: "Cumplimiento",
    name: "Cumplimiento",
  },
  {
    code: "Riesgo",
    name: "Riesgo",
  },
  {
    code: "Servicio Al Cliente",
    name: "Servicio Al Cliente",
  },
  {
    code: "Ventas",
    name: "Ventas",
  },
  {
    code: "Cobros",
    name: "Cobros",
  },
  {
    code: "Contabilidad",
    name: "Contabilidad",
  },
  {
    code: "Procesos",
    name: "Procesos",
  },
  {
    code: "Datos",
    name: "Datos",
  },
  {
    code: "Proyectos",
    name: "Proyectos",
  },
  {
    code: "Banca Digital",
    name: "Banca Digital",
  },
  {
    code: "Contraloría",
    name: "Contraloría",
  },
  {
    code: "Otros",
    name: "Otros",
  },
];

export const paises = {
  Afganistán: "AF",
  Albania: "AL",
  Alemania: "DE",
  Andorra: "AD",
  Angola: "AO",
  "Antigua y Barbuda": "AG",
  "Arabia Saudita": "SA",
  Argelia: "DZ",
  Argentina: "AR",
  Armenia: "AM",
  Australia: "AU",
  Austria: "AT",
  Azerbaiyán: "AZ",
  Bahamas: "BS",
  Bangladés: "BD",
  Barbados: "BB",
  Baréin: "BH",
  Bélgica: "BE",
  Belice: "BZ",
  Benín: "BJ",
  Bielorrusia: "BY",
  Birmania: "MM",
  Bolivia: "BO",
  "Bosnia y Herzegovina": "BA",
  Botsuana: "BW",
  Brasil: "BR",
  Brunéi: "BN",
  Bulgaria: "BG",
  "Burkina Faso": "BF",
  Burundi: "BI",
  Bután: "BT",
  "Cabo Verde": "CV",
  Camboya: "KH",
  Camerún: "CM",
  Canadá: "CA",
  Catar: "QA",
  Chad: "TD",
  Chile: "CL",
  China: "CN",
  Chipre: "CY",
  Colombia: "CO",
  Comoras: "KM",
  Congo: "CG",
  "Corea del Norte": "KP",
  "Corea del Sur": "KR",
  "Costa de Marfil": "CI",
  "Costa Rica": "CR",
  Croacia: "HR",
  Cuba: "CU",
  Dinamarca: "DK",
  Djibouti: "DJ",
  Dominica: "DM",
  Ecuador: "EC",
  Egipto: "EG",
  "El Salvador": "SV",
  "Emiratos Árabes Unidos": "AE",
  Eritrea: "ER",
  Eslovaquia: "SK",
  Eslovenia: "SI",
  España: "ES",
  "Estados Unidos": "US",
  Estonia: "EE",
  Etiopía: "ET",
  Fiyi: "FJ",
  Filipinas: "PH",
  Finlandia: "FI",
  Francia: "FR",
  Gabón: "GA",
  Gambia: "GM",
  Georgia: "GE",
  Ghana: "GH",
  Granada: "GD",
  Grecia: "GR",
  Guatemala: "GT",
  Guinea: "GN",
  "Guinea-Bisáu": "GW",
  "Guinea Ecuatorial": "GQ",
  Guyana: "GY",
  Haití: "HT",
  Honduras: "HN",
  Hungría: "HU",
  India: "IN",
  Indonesia: "ID",
  Irak: "IQ",
  Irán: "IR",
  Irlanda: "IE",
  Islandia: "IS",
  "Islas Marshall": "MH",
  "Islas Salomón": "SB",
  Israel: "IL",
  Italia: "IT",
  Jamaica: "JM",
  Japón: "JP",
  Jordania: "JO",
  Kazajistán: "KZ",
  Kenia: "KE",
  Kirguistán: "KG",
  Kiribati: "KI",
  Kuwait: "KW",
  Laos: "LA",
  Lesoto: "LS",
  Letonia: "LV",
  Líbano: "LB",
  Liberia: "LR",
  Libia: "LY",
  Liechtenstein: "LI",
  Lituania: "LT",
  Luxemburgo: "LU",
  "Macedonia del Norte": "MK",
  Madagascar: "MG",
  Malasia: "MY",
  Malaui: "MW",
  Maldivas: "MV",
  Malí: "ML",
  Malta: "MT",
  Marruecos: "MA",
  Mauricio: "MU",
  Mauritania: "MR",
  México: "MX",
  Micronesia: "FM",
  Moldavia: "MD",
  Mónaco: "MC",
  Mongolia: "MN",
  Montenegro: "ME",
  Mozambique: "MZ",
  Namibia: "NA",
  Nauru: "NR",
  Nepal: "NP",
  Nicaragua: "NI",
  Níger: "NE",
  Nigeria: "NG",
  Noruega: "NO",
  "Nueva Zelanda": "NZ",
  Omán: "OM",
  "Países Bajos": "NL",
  Pakistán: "PK",
  Palaos: "PW",
  Panamá: "PA",
  "Papúa Nueva Guinea": "PG",
  Paraguay: "PY",
  Perú: "PE",
  Polonia: "PL",
  Portugal: "PT",
  "Reino Unido": "GB",
  "República Centroafricana": "CF",
  "República Checa": "CZ",
  "República del Congo": "CD",
  "República Dominicana": "DO",
  Ruanda: "RW",
  Rumania: "RO",
  Rusia: "RU",
  Samoa: "WS",
  "San Cristóbal y Nieves": "KN",
  "San Marino": "SM",
  "Santa Lucía": "LC",
  "Santa Vicente y las Granadinas": "VC",
  "Santo Tomé y Príncipe": "ST",
  Senegal: "SN",
  Serbia: "RS",
  Seychelles: "SC",
  "Sierra Leona": "SL",
  Singapur: "SG",
  Siria: "SY",
  Somalia: "SO",
  "Sri Lanka": "LK",
  Sudáfrica: "ZA",
  Sudán: "SD",
  "Sudán del Sur": "SS",
  Suecia: "SE",
  Suiza: "CH",
  Surinam: "SR",
  Suazilandia: "SZ",
  Tailandia: "TH",
  Tanzania: "TZ",
  Tayikistán: "TJ",
  "Timor Oriental": "TL",
  Togo: "TG",
  Tonga: "TO",
  "Trinidad y Tobago": "TT",
  Túnez: "TN",
  Turkmenistán: "TM",
  Turquía: "TR",
  Tuvalu: "TV",
  Ucrania: "UA",
  Uganda: "UG",
  Uruguay: "UY",
  Uzbekistán: "UZ",
  Vanuatu: "VU",
  Vaticano: "VA",
  Venezuela: "VE",
  Vietnam: "VN",
  Yemen: "YE",
  Yibuti: "DJ",
  Zambia: "ZM",
  Zimbabue: "ZW",
};
// Crear un arreglo de diccionarios a partir del objeto paises
export const arregloPaises = Object.entries(paises).map(([nombre, abreviatura]) => ({
  nombre,
  abreviatura,
}));

import { userActions } from "../store/user-store/user-slice";

export const isValidEmail = (email) => {
  return /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(email);
};

export const validateRegisterFields = (index, usuario, dispatch) => {
  let value = true;
  switch (index) {
    case 0:
      if (
        usuario.email === "" ||
        usuario.email === undefined ||
        !isValidEmail(usuario.email)
      ) {
        dispatch(userActions.setFieldValid({ id: "email", value: false }));
        value = false;
      }
      if (usuario.password === "" || usuario.password === undefined) {
        dispatch(
          userActions.setFieldValid({ id: "password", value: false })
        );
        value = false;
      }
      if (usuario.repassword === "" || usuario.repassword === undefined) {
        dispatch(
          userActions.setFieldValid({ id: "repassword", value: false })
        );
        value = false;
      }
      if (usuario.password !== usuario.repassword) {
        dispatch(
          userActions.setFieldValid({ id: "password", value: false })
        );
        dispatch(
          userActions.setFieldValid({ id: "repassword", value: false })
        );
        value = false;
      }
      break;
    case 1:
      if (usuario.nombres === "" || usuario.nombres === undefined) {
        dispatch(userActions.setFieldValid({ id: "nombres", value: false }));
        value = false;
      }
      if (usuario.apellidos === "" || usuario.apellidos === undefined) {
        dispatch(
          userActions.setFieldValid({ id: "apellidos", value: false })
        );
        value = false;
      }
      if (
        usuario.identificacion === "" ||
        usuario.identificacion === undefined
      ) {
        dispatch(
          userActions.setFieldValid({ id: "identificacion", value: false })
        );
        value = false;
      }
      if (
        usuario.telefonoCelular === "" ||
        usuario.telefonoCelular === undefined
      ) {
        dispatch(
          userActions.setFieldValid({ id: "telefonoCelular", value: false })
        );
        value = false;
      }
      break;
    case 2:
      if (usuario.pais === "" || usuario.pais === undefined) {
        dispatch(userActions.setFieldValid({ id: "pais", value: false }));
        value = false;
      }
      if (usuario.ciudad === "" || usuario.ciudad === undefined) {
        dispatch(userActions.setFieldValid({ id: "ciudad", value: false }));
        value = false;
      }
      if (usuario.direccion === "" || usuario.direccion === undefined) {
        dispatch(
          userActions.setFieldValid({ id: "direccion", value: false })
        );
        value = false;
      }
      break;
    case 3:
      if (usuario.entidadLabora === "" || usuario.entidadLabora === undefined) {
        dispatch(
          userActions.setFieldValid({ id: "entidadLabora", value: false })
        );
        value = false;
      }
      if (usuario.cargo === "" || usuario.cargo === undefined) {
        dispatch(userActions.setFieldValid({ id: "cargo", value: false }));
        value = false;
      }
      if (usuario.departamento === "" || usuario.departamento === undefined) {
        dispatch(
          userActions.setFieldValid({ id: "departamento", value: false })
        );
        value = false;
      }
      break;
    default:
      value = true;
  }
  return value;
};
export const validateAllFields = (usuario, dispatch) => {
  let value = true;
  if (
    usuario.email === "" ||
    usuario.email === undefined ||
    !isValidEmail(usuario.email)
  ) {
    dispatch(userActions.setFieldValid({ id: "email", value: false }));
    value = false;
  } else {
    dispatch(userActions.setFieldValid({ id: "email", value: true }));
  }
  if (usuario.nombres === "" || usuario.nombres === undefined) {
    dispatch(userActions.setFieldValid({ id: "nombres", value: false }));
    value = false;
  } else {
    dispatch(userActions.setFieldValid({ id: "nombres", value: true }));
  }
  if (usuario.apellidos === "" || usuario.apellidos === undefined) {
    dispatch(userActions.setFieldValid({ id: "apellidos", value: false }));
    value = false;
  } else {
    dispatch(userActions.setFieldValid({ id: "apellidos", value: true }));
  }
  if (usuario.identificacion === "" || usuario.identificacion === undefined) {
    dispatch(
      userActions.setFieldValid({ id: "identificacion", value: false })
    );
    value = false;
  } else {
    dispatch(userActions.setFieldValid({ id: "apellidos", value: true }));
  }
  if (usuario.telefonoCelular === "" || usuario.telefonoCelular === undefined) {
    dispatch(
      userActions.setFieldValid({ id: "telefonoCelular", value: false })
    );
    value = false;
  } else {
    dispatch(userActions.setFieldValid({ id: "telefonoCelular", value: true }));
  }
  if (usuario.pais === "" || usuario.pais === undefined) {
    dispatch(userActions.setFieldValid({ id: "pais", value: false }));
    value = false;
  } else {
    dispatch(userActions.setFieldValid({ id: "pais", value: true }));
  }
  if (usuario.ciudad === "" || usuario.ciudad === undefined) {
    dispatch(userActions.setFieldValid({ id: "ciudad", value: false }));
    value = false;
  } else {
    dispatch(userActions.setFieldValid({ id: "ciudad", value: true }));
  }
  if (usuario.direccion === "" || usuario.direccion === undefined) {
    dispatch(userActions.setFieldValid({ id: "direccion", value: false }));
    value = false;
  } else {
    dispatch(userActions.setFieldValid({ id: "direccion", value: true }));
  }
  if (usuario.entidadLabora === "" || usuario.entidadLabora === undefined) {
    dispatch(
      userActions.setFieldValid({ id: "entidadLabora", value: false })
    );
    value = false;
  } else {
    dispatch(userActions.setFieldValid({ id: "entidadLabora", value: true }));
  }
  if (usuario.cargo === "" || usuario.cargo === undefined) {
    dispatch(userActions.setFieldValid({ id: "cargo", value: false }));
    value = false;
  } else {
    dispatch(userActions.setFieldValid({ id: "cargo", value: true }));
  }
  if (usuario.departamento === "" || usuario.departamento === undefined) {
    dispatch(
      userActions.setFieldValid({ id: "departamento", value: false })
    );
    value = false;
  } else {
    dispatch(userActions.setFieldValid({ id: "departamento", value: true }));
  }
  return value;
};

import { userActions } from "./user-slice";
import { uiActions } from "../ui-slice";

export const RegisterUsuario = (user, email, ConferecenCodeName) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Realizando nuevo registro de usuario",
        message: "Solicitando Creación del Usuario, por favor espere.",
      })
    );
    const GetUserByIdInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}conferecences/user/register`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            ConferecenCodeName: ConferecenCodeName,
            ...user,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo crear el usuario");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await GetUserByIdInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          userActions.setuser({
            user: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Usuario Creado",
            message: response.result.message,
          })
        );
        dispatch(userActions.setIsRegister(true));
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo crear el usuario",
        })
      );
    }
  };
};
export const ValidateToken = (token, id) => {
  return async (dispatch) => {
    const validateInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}user/validateToken`,
        {
          method: "POST",
          body: JSON.stringify({
            Id: id,
            TokenValidation: {
              Token: token,
              TokenType: "Register",
            },
            userName: "tokenvalidation@aba.org.do",
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se validar el token");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await validateInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Token validado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo validar el token",
        })
      );
    }
  };
};
export const ForgotPasswordAction = (email, conferenceCode) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitud realizada",
        message: "Se esta solicitando el cambio de contraseña",
      })
    );
    const loginInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}conferences/user/forgotpassword`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Email: email,
            ConferecenCodeName: conferenceCode,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo solicitar el cambio de contraseña");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await loginInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          userActions.setuser({
            usuario: {
              email: response.data.email,
              fullName: response.data.fullName,
            },
          })
        );
        localStorage.setItem("token", response.data.token);
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Solicitud realizada",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo solicitar el cambio de contraseña",
        })
      );
    }
  };
};
export const ForgotPasswordInsideAction = (email, conferenceCode) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitud realizada",
        message: "Se esta solicitando el cambio de contraseña",
      })
    );
    const loginInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}conferences/user/forgotpassword`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Email: email,
            ConferecenCodeName: conferenceCode,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo solicitar el cambio de contraseña");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await loginInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          userActions.setuser({
            usuario: {
              email: response.data.email,
              fullName: response.data.fullName,
            },
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Solicitud realizada",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo solicitar el cambio de contraseña",
        })
      );
    }
  };
};
export const ChangePasswordAction = (
  token,
  userId,
  password,
  conferenceCode
) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando Cambio de contraseña",
        message: "Solicitando Cambio de contraseña al servidor.",
      })
    );
    const validateInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}conferences/user/changepassword`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: "recoverypassword@aba.org.do",
            ConferecenCodeName: conferenceCode,
            id: userId,
            password: password,
            TokenValidation: {
              Token: token,
              TokenType: "forgotpassword",
            },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo realizar el cambio de contraseña ");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await validateInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "cambio de contraseña completado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo realizar el cambio de contraseña",
        })
      );
    }
  };
};
export const SingIn = (email, password, conferecenCodeName) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Iniciando Sesión",
        message: "",
      })
    );
    const loginInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}conferences/user/login`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Email: email,
            Password: password,
            ConferecenCodeName: conferecenCodeName,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se realizar el inicio de sesión");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await loginInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Bienvenido",
            message: "Inicio de sesión exitoso",
          })
        );
        dispatch(userActions.settoken({ token: response.data.token }));
        localStorage.setItem("token", response.data.token);
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo realizar el inicio de sesiónn",
        })
      );
    }
  };
};
export const UpdateDetail = (email, user) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Actualizando datos",
        message: "",
      })
    );
    const loginInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}conference/user/updateDetail`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: user.id,
            Name: user.name,
            LastName: user.lastName,
            CellPhone: user.cellPhone,
            Country: user.country,
            Company: user.company,
            Charge: user.charge,
            Department: user.department,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se realizar el inicio de sesión");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await loginInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Dataos actualizados",
            message: "Sus datos han sido actualizados",
          })
        );
        // dispatch(userActions.settoken({ token: response.data.token }));
        localStorage.setItem("token", response.data.token);
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar los datos",
        })
      );
    }
  };
};
export const GetUserByemail = (email, userName, users, dontShow) => {
  return async (dispatch) => {
    if (dontShow === undefined) {
     
    }
    const getUsuarioInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/getByEmail`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: userName,
            Email: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener el usuario");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getUsuarioInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          userActions.setuser({
            user: response.data,
            users: users,
          })
        );
        if (dontShow === undefined) {
          dispatch(
            uiActions.showNotification({
              status: "success",
              title: "Datos Cargados",
              message: response.result.message,
            })
          );
        }
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          userActions.setuser({
            user: {},
            users: users,
          })
        );
        if (dontShow === undefined) {
          dispatch(
            uiActions.showNotification({
              status: "warning",
              title: "Advertencia",
              message: response.result.message,
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener el Usuario",
        })
      );
    }
  };
};
export const CleanUser = () => {
  return async (dispatch) => {
    try {
      dispatch(
        userActions.setuser({
          user: {
            email: "",
            name: "",
            lastName: "",
            identification: "",
            cellPhone: "",
            country: "",
            city: "",
            address: "",
            company: "",
            charge: {},
            department: {},
            status: false,
            token: "",
            fullName: "",
          },
        })
      );
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          // message: "No se pudo obtener el Usuario",
        })
      );
    }
  };
};
import React, { useEffect, useState } from "react";
import { Card } from "primereact/card";

import "./OrdenPayTC.css";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { GetOrdensById } from "../../../store/orden-store/orden-actions";
import { getTokenInfo } from "../../../utils/Guard";
import Loading from "../../../components/UI/Loading";
import { GetHashCongresos } from "../../../utils/GeHash";
import { useParams } from "react-router-dom";

const OrdenPayTC = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const orden = useSelector((state) => state.orden.orden);

  const userInfo = getTokenInfo();
  const [amountDollar, setAmountDollar] = useState(0);
  const [amountRD, setAmountRD] = useState(0);
  const [count, setCount] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  let amountToPay = "0";

  useEffect(() => {
    if (orden && (orden.id === undefined || orden.id === 0)) {
      dispatch(GetOrdensById(userInfo.OrdenId, userInfo.Email));
    }
  }, [dispatch, orden, userInfo.Email, userInfo.OrdenId]);

  useEffect(() => {
    if (orden && orden.id !== undefined && orden.id > 0) {
      let counting = 0;
      orden.users.forEach((user) => {
        if (user.id !== 0) {
          counting++;
        }
      });
      setCount(counting);
    }
  }, [orden, count]);

  useEffect(() => {
    if (orden && orden.id !== undefined && orden.id !== 0) {
      if (
        orden.discount &&
        orden.discount.id !== undefined &&
        orden.discount.id !== 0 &&
        count > 0
      ) {
        let discount = 0;
        if (orden.discount.percentage) {
          discount =
            orden.conference.price *
            count *
            (orden.discount.discountPercentage / 100);
        }
        if (orden.discount.nominal) {
          discount = orden.discount.discountNominal * count;
        }
        const dollar = orden.conference.price * count;
        const rd = (dollar - discount) * orden.dollarRate;

        setAmountDollar(dollar - discount);
        setAmountRD(rd);
      } else {
        const dollar = orden.conference.price * count;
        const rd = dollar * orden.dollarRate;

        setAmountDollar(dollar);
        setAmountRD(rd);
      }
    }
  }, [orden, count]);

  useEffect(() => {
    if (window.innerWidth <= 430 && !isMobile) {
      setIsMobile(true);
    }
  }, []);

  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  let moneyFormat = new Intl.NumberFormat("en-US", {
    // style: "currency",
    currency: "USD",
    useGrouping: false,
    minimumFractionDigits: 2,
  });

  amountToPay = moneyFormat.format(amountRD);
  const CancelUrl =
    process.env.REACT_APP_MERCHANTID_URL + "home/" + params.conferenceCode;
  const ApprovedUrl =
    process.env.REACT_APP_MERCHANTID_URL +
    "orden/Pay/Approved/" +
    params.conferenceCode +
    "/?AprovedUrl";
  const DeclinedUrl =
    process.env.REACT_APP_MERCHANTID_URL +
    "orden/Pay/Declined/" +
    params.conferenceCode +
    "/?DeclinedUrl/";

  return (
    <div className="card flex justify-content-center ">
      <div className="formgrid grid">
        <div className="field col-12 md:col-12">
          <Card className="ordenPayTcBox">
            {orden && (orden.id === undefined || orden.id === 0) && <Loading />}
            {orden && orden.id !== undefined && orden.id > 0 && (
              <div className="formgrid grid">
                <div className="field col-12 md:col-12 ordenPayTcTitle ">
                  <Button
                    icon="pi pi-credit-card"
                    disabled={true}
                    rounded
                    aria-label="Filter"
                    className="buttonCheck"
                  />
                  <br />
                  <br />
                  <br />
                </div>
                {!isMobile && (
                  <>
                    <div className="field col-12 md:col-12 ordenPayTcTitle ">
                      Pagar con Tarjeta de Crédito o Débito
                    </div>
                    <div className="field col-12 md:col-12 ordenPayTCSubtitle ">
                      <strong style={{ fontWeight: "800", color: "#000000" }}>
                        Número de órden:
                      </strong>{" "}
                      {orden.id}
                    </div>
                    <div className="field col-12 md:col-4 ordenPayTCSubtitle ">
                      <span>
                        <strong style={{ fontWeight: "800", color: "#000000" }}>
                          Monto  en dólares
                        </strong>
                        <br /> US{USDollar.format(amountDollar)}
                      </span>
                    </div>
                    <div className="field col-12 md:col-4 ordenPayTCSubtitle ">
                      <span>
                        <strong style={{ fontWeight: "800", color: "#000000" }}>
                          Tasa
                        </strong>{" "}
                        RD{USDollar.format(orden.dollarRate)}
                      </span>
                    </div>
                    <div className="field col-12 md:col-4 ordenPayTCSubtitle ">
                      <span>
                        <strong style={{ fontWeight: "800", color: "#000000" }}>
                          Monto  en pesos
                        </strong>
                        <br /> RD{USDollar.format(amountRD)}
                      </span>
                    </div>
                  </>
                )}
                {isMobile && (
                  <>
                    <div className="field col-12 md:col-12 ordenPayTcTitle ">
                      Pagar con Tarjeta de Crédito o Débito
                    </div>
                    <div className="field col-12 md:col-12 ordenPayTCSubtitle ">
                      <strong style={{ fontWeight: "800", color: "#000000" }}>
                        Número de órden:
                      </strong>{" "}
                      {orden.id}
                    </div>
                    <div className="field col-12 md:col-12 ordenPayTCSubtitle ">
                      <span>
                        <strong style={{ fontWeight: "800", color: "#000000" }}>
                          Monto dollares
                        </strong>
                        <br /> US{USDollar.format(amountDollar)}
                      </span>
                    </div>
                    <div className="field col-12 md:col-12"></div>
                    <div className="field col-12 md:col-12"></div>
                    <div className="field col-12 md:col-12 ordenPayTCSubtitle ">
                      <span>
                        <strong style={{ fontWeight: "800", color: "#000000" }}>
                          Tasa
                        </strong><br />
                        RD{USDollar.format(orden.dollarRate)}
                      </span>
                    </div>
                    <div className="field col-12 md:col-12"></div>
                    <div className="field col-12 md:col-12"></div>
                    <div className="field col-12 md:col-12 ordenPayTCSubtitle ">
                      <span>
                        <strong style={{ fontWeight: "800", color: "#000000" }}>
                          Monto  en pesos
                        </strong>
                        <br /> RD{USDollar.format(amountRD)}
                      </span>
                    </div>
                  </>
                )}
                <div className="field col-12 md:col-12 ordenPayTcTitle ">
                  <br />
                  <br />
                  <br />

                  <form action={process.env.REACT_APP_PAYMET_URL} method="post">
                    <input
                      type="hidden"
                      id="MerchantId"
                      name="MerchantId"
                      value={process.env.REACT_APP_MERCHANTID_CONGRESOS}
                    />
                    <input
                      type="hidden"
                      id="MerchantName"
                      name="MerchantName"
                      value={process.env.REACT_APP_MERCHANT_NAME}
                    />
                    <input
                      type="hidden"
                      id="MerchantType"
                      name="MerchantType"
                      value={process.env.REACT_APP_MERCHANT_TYPE}
                    />
                    <input
                      type="hidden"
                      id="CurrencyCode"
                      name="CurrencyCode"
                      value="$"
                    />
                    <input
                      type="hidden"
                      id="OrderNumber"
                      name="OrderNumber"
                      value={orden.id}
                    />
                    <input
                      type="hidden"
                      id="Amount"
                      name="Amount"
                      value={amountToPay.replace(".", "")}
                    />
                    <input
                      type="hidden"
                      id="ITBIS"
                      name="ITBIS"
                      value={process.env.REACT_APP_MERCHANT_ITBIS}
                    />
                    <input
                      type="hidden"
                      id="ApprovedUrl"
                      name="ApprovedUrl"
                      value={ApprovedUrl}
                    />
                    <input
                      type="hidden"
                      id="DeclinedUrl"
                      name="DeclinedUrl"
                      value={DeclinedUrl}
                    />
                    <input
                      type="hidden"
                      id="CancelUrl"
                      name="CancelUrl"
                      value={CancelUrl}
                    />

                    <input
                      type="hidden"
                      id="UseCustomField1"
                      name="UseCustomField1"
                      value={process.env.REACT_APP_MERCHANT_USECUSTOMFIELD1}
                    />
                    <input
                      type="hidden"
                      id="CustomField1Label"
                      name="CustomField1Label"
                      value={
                        process.env.REACT_APP_MERCHANT_USECUSTOMFIELD1_LABEL
                      }
                    />
                    <input
                      type="hidden"
                      id="CustomField1Value"
                      name="CustomField1Value"
                      value={
                        process.env.REACT_APP_MERCHANT_USECUSTOMFIELD1_VALUE
                      }
                    />
                    <input
                      type="hidden"
                      id="UseCustomField2"
                      name="UseCustomField2"
                      value={process.env.REACT_APP_MERCHANT_USECUSTOMFIELD2}
                    />
                    <input
                      type="hidden"
                      id="CustomField2Label"
                      name="CustomField2Label"
                      value={
                        process.env.REACT_APP_MERCHANT_USECUSTOMFIELD2_LABEL
                      }
                    />
                    <input
                      type="hidden"
                      id="CustomField2Value"
                      name="CustomField2Value"
                      value={
                        process.env.REACT_APP_MERCHANT_USECUSTOMFIELD2_VALUE
                      }
                    />

                    <input
                      type="hidden"
                      id="AuthHash"
                      name="AuthHash"
                      value={GetHashCongresos(
                        orden.id,
                        amountToPay,
                        CancelUrl,
                        ApprovedUrl,
                        DeclinedUrl
                      )}
                    />
                    <Button className="buttonPay" label="Ir a Pagar" />
                  </form>
                </div>
              </div>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};
export default OrdenPayTC;

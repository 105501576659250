import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { Card } from "primereact/card";

import Loading from "../../../components/UI/Loading";

import {
  GetOrdensById,
  UpdatePayment,
} from "../../../store/orden-store/orden-actions";
import { getTokenInfo } from "../../../utils/Guard";

import "./OrdenApprove.css";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

let update = true;
const OrdenApprove = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const params = useParams();

  const userInfo = getTokenInfo();
  const orden = useSelector((state) => state.orden.orden);
  const [amountDollar, setAmountDollar] = useState(0);
  const [amountRD, setAmountRD] = useState(0);
  const [count, setCount] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const paymentInfo = {
    OrderNumber: searchParams.get("OrderNumber"),
    Amount: amountRD,
    AuthorizationCode: searchParams.get("AuthorizationCode"),
    DateTime: searchParams.get("DateTime"),
    ResponseCode: searchParams.get("ResponseCode"),
    IsoCode: searchParams.get("IsoCode"),
    ResponseMessage:
      searchParams.get("ResponseMessage") === "ERROR"
        ? "Declinada"
        : searchParams.get("ResponseMessage"),
    ErrorDescription: searchParams.get("ErrorDescription"),
    RRN: searchParams.get("RRN"),
    CustomOrderId: searchParams.get("OrderNumber"),
    CardNumber: searchParams.get("CardNumber").split("****")[1],
    AzulOrderId: searchParams.get("AzulOrderId"),
  };

  useEffect(() => {
    if (orden && (orden.id === undefined || orden.id === 0)) {
      dispatch(GetOrdensById(userInfo.OrdenId, userInfo.Email));
    }
  }, [dispatch, orden, userInfo.Email, userInfo.OrdenId]);

  useEffect(() => {
    if (orden && orden.id !== undefined && orden.id > 0) {
      let counting = 0;
      orden.users.forEach((user) => {
        if (user.id !== 0) {
          counting++;
        }
      });
      setCount(counting);
    }
  }, [orden, count]);

  useEffect(() => {
    if (orden && orden.id !== undefined && orden.id !== 0) {
      if (
        orden.discount &&
        orden.discount.id !== undefined &&
        orden.discount.id !== 0 &&
        count > 0
      ) {
        let discount = 0;
        if (orden.discount.percentage) {
          discount =
            orden.conference.price *
            count *
            (orden.discount.discountPercentage / 100);
        }
        if (orden.discount.nominal) {
          discount = orden.discount.discountNominal * count;
        }
        const dollar = orden.conference.price * count;
        const rd = (dollar - discount) * orden.dollarRate;

        setAmountDollar(dollar - discount);
        setAmountRD(rd);
      } else {
        const dollar = orden.conference.price * count;
        const rd = dollar * orden.dollarRate;
        setAmountDollar(dollar);
        setAmountRD(rd);
      }
    }
  }, [orden, count]);
  useEffect(() => {
    if (orden && orden.id !== undefined && orden.id > 0 && update) {
      update = false;
      dispatch(UpdatePayment(orden.id, userInfo.Email, paymentInfo));
    }
  }, [orden]);
  useEffect(() => {
    if (window.innerWidth <= 430 && !isMobile) {
      setIsMobile(true);
    }
  }, []);

  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <>
      <div className="card flex justify-content-center ">
        <div className="formgrid grid">
          <div className="field col-12 md:col-12">
            <Card className="ordenApprovedBox">
              {orden && (orden.id === undefined || orden.id === 0) && (
                <Loading />
              )}
              {orden && orden.id !== undefined && orden.id > 0 && (
                <div className="formgrid grid">
                  <div className="field col-12 md:col-12 ">
                    <Button
                      id="buttonCheck"
                      icon="pi pi-check"
                      disabled={true}
                      rounded
                      aria-label="Filter"
                      className="ordenApprovedButtonCheck"
                    />
                    <br />
                    <br />
                    <br />
                  </div>
                  {!isMobile && (
                    <>
                      <div className="field col-12 md:col-12 ordenApprovedTitle ">
                        Pago Completado{" "}
                      </div>
                      <div className="field col-12 md:col-12 ordenApprovedSubtitle ">
                        <strong style={{ fontWeight: "800", color: "#000000" }}>
                          Número de órden:
                        </strong>{" "}
                        {orden.id}
                      </div>
                      <div className="field col-12 md:col-4 ordenApprovedSubtitle ">
                        <span>
                          <strong
                            style={{ fontWeight: "800", color: "#000000" }}
                          >
                            Monto  en dólares
                          </strong>
                          <br /> US{USDollar.format(amountDollar)}
                        </span>
                      </div>
                      <div className="field col-12 md:col-4 ordenApprovedSubtitle ">
                        <span>
                          <strong
                            style={{ fontWeight: "800", color: "#000000" }}
                          >
                            Tasa
                          </strong>{" "}
                          <br />
                          RD{USDollar.format(orden.dollarRate)}
                        </span>
                      </div>
                      <div className="field col-12 md:col-4 ordenApprovedSubtitle ">
                        <span>
                          <strong
                            style={{ fontWeight: "800", color: "#000000" }}
                          >
                            Monto  en pesos
                          </strong>
                          <br /> RD{USDollar.format(amountRD)}
                        </span>
                      </div>
                      <div className="field col-12 md:col-12 ordenApprovedSubtitle ">
                        <Button
                          className="ordenApprovedButtonPay"
                          label="Inicio"
                          onClick={() => {
                            navigate(`/home/${params.conferenceCode}`);
                          }}
                        />
                      </div>
                    </>
                  )}
                  {isMobile && (
                    <>
                      <div className="field col-12 md:col-12 ordenApprovedTitle ">
                        Pago Completado{" "}
                      </div>
                      <div className="field col-12 md:col-12 ordenApprovedSubtitle ">
                        <strong style={{ fontWeight: "800", color: "#000000" }}>
                          Número de órden:
                        </strong>{" "}
                        {orden.id}
                      </div>
                      <div className="field col-12 md:col-12 ordenApprovedSubtitle ">
                        <span>
                          <strong
                            style={{ fontWeight: "800", color: "#000000" }}
                          >
                            Monto dollares
                          </strong>
                          <br /> US{USDollar.format(amountDollar)}
                        </span>
                      </div>
                      <div className="field col-12 md:col-12"></div>
                      <div className="field col-12 md:col-12 ordenApprovedSubtitle ">
                        <span>
                          <strong
                            style={{ fontWeight: "800", color: "#000000" }}
                          >
                            Tasa
                          </strong>{" "}
                          <br />
                          RD{USDollar.format(orden.dollarRate)}
                        </span>
                      </div>
                      <div className="field col-12 md:col-12"></div>
                      <div className="field col-12 md:col-12 ordenApprovedSubtitle ">
                        <span>
                          <strong
                            style={{ fontWeight: "800", color: "#000000" }}
                          >
                            Monto  en pesos
                          </strong>
                          <br /> RD{USDollar.format(amountRD)}
                        </span>
                      </div>
                      <div className="field col-12 md:col-12 ordenApprovedSubtitle ">
                        <Button
                          className="ordenApprovedButtonPay"
                          label="Inicio"
                          onClick={() => {
                            navigate(`/home/${params.conferenceCode}`);
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};
export default OrdenApprove;

import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { ChangePasswordAction } from "../../../../store/user-store/user-actions";
import { uiActions } from "../../../../store/ui-slice";

import "./ChangePassword.css";
import { getImage } from "../../../../utils/Guard";

let restartPage = false;

const ChangePassword = () => {
  const params = useParams();
  const notification = useSelector((state) => state.ui.notification);
  const [password, setPassword] = useState("");
  const [repassword, setRePassword] = useState("");
  const [passwordIsValid, setPasswordIsvalid] = useState(true);
  const [repasswordIsValid, setRePasswordIsValid] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [reshowPassword, setReShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (notification && notification.status === "success" && restartPage) {
      window.location.href = "/login/" + params.conferenceCode;
    }
  }, [navigate, notification, params.conferenceCode]);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (password === "") {
      setPasswordIsvalid(false);
    } else if (repassword === "") {
      setRePasswordIsValid(false);
    } else if (password !== repassword) {
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Advertencia",
          message: "Las contraseñas no coinciden",
        })
      );
    } else {
      dispatch(
        ChangePasswordAction(
          params.token,
          params.userId,
          password,
          params.conferenceCode
        )
      );
      restartPage = true;
    }
  };
  const getFormErrorMessage = (id) => {
    let response = <small className="p-warn">&nbsp;</small>;
    if (id === "password" && !passwordIsValid) {
      response = (
        <Message severity="warn" text="Debe Digitar una contraseña valida." />
      );
    }
    if (id === "repassword" && !repasswordIsValid) {
      response = (
        <Message severity="warn" text="Debe Digitar una contraseña valida." />
      );
    }
    return response;
  };
  const header = (
    <img alt="Card" className="changePasswordImage" src={getImage(params.conferenceCode)} />
  );

  return (
    <>
      <div className="card flex justify-content-center changePasswordBody">
        <div className="formgrid grid ">
          <div className="field col-12 md:col-12">
            <Card className="changePasswordCard">
              <div className="field col-12 md:col-12">{header}</div>
              <div className="field col-12 md:col-3"></div>
              <div className="field col-12 md:col-12 changePasswordTitle">
                <span className="changePasswordTitleText">
                  Recuperar contraseña
                </span>
              </div>
              <form onSubmit={onSubmitHandler}>
                <div className="formgrid grid">
                  <div className="field col-12 md:col-12">
                    <InputText
                      className="changePasswordemailTextBox"
                      type={showPassword === false ? "password" : "text"}
                      id={password}
                      value={password}
                      onChange={(e) => {
                        if (e.target.value.length > 0) {
                          setPasswordIsvalid(true);
                        } else {
                          setPasswordIsvalid(false);
                        }
                        setPassword(e.target.value);
                      }}
                      placeholder="Contraseña"
                    />
                    <Link
                      className="changePasswordshowPassword"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      <i
                        className={
                          showPassword === false
                            ? "pi pi-eye"
                            : "pi  pi-eye-slash"
                        }
                      ></i>
                    </Link>
                    <div>{getFormErrorMessage("password")}</div>
                  </div>
                  <div className="field col-12 md:col-12">
                    <InputText
                      type={reshowPassword === false ? "password" : "text"}
                      className="changePasswordemailTextBox"
                      id={repassword}
                      value={repassword}
                      onChange={(e) => {
                        if (e.target.value.length > 0) {
                          setRePasswordIsValid(true);
                        } else {
                          setRePasswordIsValid(false);
                        }
                        setRePassword(e.target.value);
                      }}
                      placeholder="Repetir Contraseña"
                    />
                    <Link
                      className="changePasswordshowPassword"
                      onClick={() => {
                        setReShowPassword(!reshowPassword);
                      }}
                    >
                      <i
                        className={
                          reshowPassword === false
                            ? "pi pi-eye"
                            : "pi  pi-eye-slash"
                        }
                      ></i>
                    </Link>
                    <div>{getFormErrorMessage("repassword")}</div>
                  </div>
                  <div className="field col-12 md:col-6">
                    <Button
                      label="Regresar"
                      className="changePasswordButtonBack"
                      onClick={() => {
                        navigate(`/login/${params.conferenceCode}`);
                      }}
                    />
                  </div>
                  <div className="field col-12 md:col-6 ">
                    <Button
                      label="Solicitar"
                      className="changePasswordButton"
                      icon={
                        notification && notification.status === "pending"
                          ? "pi pi-spin pi-spinner"
                          : ""
                      }
                      disabled={
                        notification && notification.status === "pending"
                      }
                    />
                  </div>
                </div>
              </form>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChangePassword;

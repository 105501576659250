import { createSlice } from "@reduxjs/toolkit";
import { getCurrentDate } from "../../utils/CurrentDate";

const initialState = {
  conferences: [],
  conference: {
    id: 0,
    name: "",
    description: "",
    location: "",
    price: 0,
    soldOut: 0,
    status: false,
    ImageUrl: "",
  },
  ImageUrl: "",
};

const conferenceSlice = createSlice({
  name: "conference",
  initialState,
  reducers: {
    restartState(state) {
      state.conference = {};
      state.conferences = [];
    },
    setConferences(state, actions) {
      state.conferences = actions.payload.conferences;
    },
    setImageUrl(state, actions) {
      state.ImageUrl = actions.payload.imageUrl;
    },
    setConference(state, actions) {
      state.conference = actions.payload.conference;
    },
    modifyPropertyValue(state, actions) {
      state.conference[actions.payload.id] = actions.payload.value;
    },
  },
});

export const conferenceActions = conferenceSlice.actions;
export default conferenceSlice;

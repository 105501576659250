import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  charges: [],
};

const chargeSlice = createSlice({
  name: "charge",
  initialState,
  reducers: {
    setCharges(state, actions) {
      state.charges = actions.payload.charges;
    },
  },
});

export const chargeActions = chargeSlice.actions;
export default chargeSlice;
